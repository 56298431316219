<template>
  <div class="marker" ref="markerRef" :style="{'--color-marker': color}">
    <!-- Text or other content passed through the slot -->
    <slot></slot>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  props: {
    widthGain: {
      type: Number,
      default: 1
    },
    heightGain: {
      type: Number,
      default: 1
    },
    color: {
      type: String,
      default: '#EDC8C9'
    }
  },
  setup(props) {
    const markerRef = ref(null);

    onMounted(() => {
      const marker = markerRef.value;
      const width = marker.offsetWidth;
      const height = 2 * marker.offsetHeight;
      const ns = "http://www.w3.org/2000/svg";

      // Create the SVG element
      const svg = document.createElementNS(ns, "svg");

      // Set styles and attributes for the SVG element
      svg.style.width = `${width}px`;
      svg.style.height = `${height}px`;
      svg.style.transform = `scale(${(2 * props.widthGain * width) / height}, ${props.heightGain})`;
      svg.setAttribute('width', width);
      svg.setAttribute('height', height);
      svg.setAttribute('viewBox', '-1 -1 2 2');

      // Attach SVG to the marker element
      marker.appendChild(svg);

      // Create the path element
      const path = document.createElementNS(ns, "path");
      path.setAttribute('pathLength', '100');
      path.setAttribute('vector-effect', 'non-scaling-stroke');

      svg.appendChild(path);

      // Initialize path visibility
      setCircle(path, false);

      // Mouseover event to show path
      marker.addEventListener('mouseover', () => {
        setCircle(path, true);
      });

      // Function to handle path drawing
      function setCircle(path, showElement) {
        path.style.visibility = showElement ? 'visible' : 'hidden';

        const pathLength = 1000 * path.getTotalLength();

        path.setAttribute('d', circlePath(-0.15, 0.05, 150, 190, 0.05, 0.3));
        path.setAttribute('stroke-dasharray', pathLength);
        path.setAttribute('stroke-dashoffset', pathLength);
      }

      // Function to generate a circle path
      function circlePath(drMin, drMax, θ0Min, θ0Max, dθMin, dθMax) {
        const c = 0.551915024494;
        const β = Math.atan(c);
        const d = Math.sqrt(c * c + 1 * 1);
        let r = 0.9;
        let θ = ((θ0Min + Math.random() * (θ0Max - θ0Min)) * Math.PI) / 180;
        let path = `M${r * Math.sin(θ)} ${r * Math.cos(θ)}`;

        path += ` C${d * r * Math.sin(θ + β)} ${d * r * Math.cos(θ + β)}`;

        for (let i = 0; i < 4; i++) {
          θ += (Math.PI / 2) * (1 + dθMin + Math.random() * (dθMax - dθMin));
          r *= 1 + drMin + Math.random() * (drMax - drMin);
          path += `${i ? ' S' : ''} ${d * r * Math.sin(θ - β)} ${d * r * Math.cos(θ - β)}`;
          path += ` ${r * Math.sin(θ)} ${r * Math.cos(θ)}`;
        }

        return path;
      }
    });

    return {
      markerRef
    };
  }
};
</script>

<style lang="scss">
.marker {
  position: relative;

  & svg {
    position: absolute;
    left: 0;
    top: -50%;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
  }
  & path {
    transition: stroke-dashoffset 300ms linear;
    stroke-width: 1.0;
    stroke: var(--color-marker);
    fill: none;
    stroke-linecap: round;
  }
  &:hover path{
    stroke-dashoffset: 0;
  }
}
._active,
.router-link-exact-active {
  .marker path{
    stroke-dashoffset: 0;
    visibility: visible !important;
  }
}
</style>
